[is=fleeb-teaser][has~=list-teaser-layout] {
    grid-template-columns: 25px 1fr;
    border-radius: 5px;
}

[has~=mobile-layout] [is=fleeb-teaser][has~=list-teaser-layout] {
    grid-template-columns: 20px 1fr;
}


[is=fleeb-teaser][has~=list-teaser-layout] [is=index-date] {
    left: auto;
    top: auto;
    right: var(--space-m);
    bottom: var(--space-s);
}


[is=fleeb-teaser][has~=book-type][has~=list-teaser-layout] [is=content],
[is=fleeb-teaser][has~=vehicle-type][has~=list-teaser-layout] [is=content],
[is=fleeb-teaser][has~=list-teaser-layout] [is=content]{
    --image-column-width: var(--space-xxl); 
    --image-paddings: var(--space-s);

    grid-template-columns: 1fr calc(var(--image-column-width) + ( 2 * var(--image-paddings)));
    grid-template-rows: calc(100% - 30px) 30px;
    grid-template-areas: 
        "topic image"
        "link image";
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;


}

/*
[is=fleeb-teaser][has~=vehicle-type][has~=list-teaser-layout] [is=content] {
    grid-template-rows: calc(100% - 30px) 30px;
}
*/
[is=fleeb-teaser][has~=list-teaser-layout][has~=custom-template] [is=content] {
    grid-template-rows: auto auto 30px;
    grid-template-areas: 
        "topic image"
        "custom image"
        "link image";
}

[is=fleeb-teaser][has~=list-teaser-layout][has~=text-layout][has~=custom-template] [is=content] {
    grid-template-columns: 1fr;
}

[is=fleeb-teaser][has~=list-teaser-layout]  [is=content][has~=offer]{
    grid-template-columns: 1fr calc(var(--image-column-width) + ( 2 * var(--image-paddings)));
    grid-template-rows: 1fr 30px 30px;
    grid-template-areas: 
        "topic image"
        "offer image"
        "link image";
}


[is=fleeb-teaser][has~=list-teaser-layout] vehicle-fleebs-teaser[is=content][has~=offer],
[is=fleeb-teaser][has~=list-teaser-layout] book-fleebs-teaser[is=content][has~=offer],
[is=fleeb-teaser][has~=list-teaser-layout] immo-fleebs-teaser[is=content][has~=offer] {
    grid-template-rows: calc(100% - 60px) 30px 30px;
}

[is=fleeb-teaser][has~=list-teaser-layout] [is=content] h2 {
    align-self: flex-start;
}

[has~=mobile-layout] [is=fleeb-teaser][has~=list-teaser-layout] [is=content] h2 {
    --font-size: var(--font-size-sm);
    --line-height: var(--font-size-m);
}


[is=fleeb-teaser][has~=list-teaser-layout] [is=text-row],
[is=fleeb-teaser][has~=list-teaser-layout][is=content] h2 {
    grid-area: topic;
}

[is=fleeb-teaser][has~=list-teaser-layout] [is=offer] {
    grid-area: offer;
    position: relative;
    background: none;
    color: var(--forground-color);
    padding: 0;
}

[is=fleeb-teaser][has~=list-teaser-layout] [is=content] [is=teaser-icon],
[is=fleeb-teaser][has~=list-teaser-layout] [is=content] picture-loader{
    position: relative;
    grid-area: image;
    padding: var(--space-s);
    display: inline-block;
}

[is=fleeb-teaser][has~=list-teaser-layout] [is=content] [is=teaser-icon]:before{
    font-size: var(--font-size-xxl);
}

[is=fleeb-teaser][has~=list-teaser-layout] [is=content] picture {
    width: var(--image-column-width);
    padding: var(--image-paddings);
    border-radius: var(--border-radius);
    aspect-ratio: 1;
}

[is=fleeb-teaser][has~=list-teaser-layout] [is=content] [is=link] {
    padding-left: var(--space-s);
    align-self: center;
}

[has~=mobile-layout] [is=fleeb-teaser][has~=list-teaser-layout] [is=content] [is=link] {
    padding-left: var(--space-xs);
}



[is=fleeb-teaser][has~=list-teaser-layout] [has*=-icon]:before {
    --font-size: var(--size-sm);
}

[is=fleeb-teaser][has~=list-teaser-layout] [is=info]{
    padding-top: 0;
    grid-template-rows: 1fr 30px;
}

[is=fleeb-teaser][has~=list-teaser-layout] [is=info] [is=icon]{
    padding-top: var(--space-xxs);

}

[is=fleeb-teaser][has~=list-teaser-layout] [is=content] [is=title-part] {
    max-height: 80%;
}   





