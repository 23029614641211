fleebs-header {

    --background: var(--background-color);
    background: var(--background);
    display: block;
}

[is=bar]{
    --padding: var(--space-s);
    --background: var(--background-color);

    padding: var(--padding);
    background: var(--background);
    display: grid;

    grid-template-columns: clamp(170px, 20vw, 20vw) 1fr clamp(40px, 20vw, 20vw);
 /*   box-shadow: var(--default-box-shadow);*/

   /* position: fixed;*/
    z-index: 3;
    width: 100%;
    margin-top: var(--top-gap);
}

[is=bar][has~=user-group]{
    grid-template-columns: clamp(170px, 20vw, 20vw) 1fr clamp(30px, 15vw, 15vw) var(--space-xl);
}

[is=options]{
    text-align: right;
    padding-right: var(--space-m);
    position: relative;
    z-index: 10;
    align-items: center;
    justify-content: end;
    align-content: flex-end;
    display: flex;
}

[is=user-group]{
    text-align: right;

    z-index: 10;
    align-items: center;
    justify-content: end;
    align-content: flex-end;
    display: flex;
}



@media (max-width: 620px) {
    [is=bar],
    body [is=bar],
    [has~=mobile-layout] [is=bar]{
        --padding: var(--space-xxs);
        --background: var(--background-color);

        grid-template-columns: 40px 1fr 40px;
    /*  position: fixed;*/
        top: var(--top-gap);
        background-color: var(--background);
    /*  box-shadow: 2px 2px 4px var(--background);*/
        z-index: 3;
        width: 100vw;
    /*  padding-bottom: var(--space-s);*/
    }

    body > app-root  > :not(home-page) [is=bar],
    [has~=mobile-layout] > app-root  > :not(home-page) [is=bar] {
        height: var(--mobile-header-height);
    }

    [is=bar][has~=user-group],
    [has~=mobile-layout] [is=bar][has~=user-group] {
        grid-template-columns: var(--size-xxl) 1fr 0 var(--size-xxl);
    }


    [is=options],
    [has~=mobile-layout] [is=options]{
        padding:0;
        grid-column: 4;
        grid-row: 1;

    }

    [is=logo],
    [has~=mobile-layout] [is=logo]{
        grid-column: 2;
        grid-row: 1;
    }

    [is=filter],
    [has~=mobile-layout] [is=filter]{
        grid-column: 3;
        grid-row: 1;
    }

    [is=user-group],
    [has~=mobile-layout] [is=user-group]{
        grid-column: 3;
        grid-row: 1;
        text-align: left;
    }

    [is=bar][has~=user-group] [is=filter],
    [has~=mobile-layout] [is=bar][has~=user-group] [is=filter]{
        grid-column: 3;
        grid-row: 1;
    }
}
