body {
    /*
    --top-gap: 30px;
    --bottom-gap: 30px;
    */
    --footer-navigation-offset: var(--space-xxs);
    
    --top-gap: env(safe-area-inset-top, '0px');
    --bottom-gap:  env(safe-area-inset-bottom, '0px');
    background: var(--background-color);
    color: var(--foreground-color);
    padding: 0;
    margin: 0;
    font-family: 'Mulish', sans-serif;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: 'Mulish', sans-serif;
    font-weight: 100;
}

li, ul, ol, dl, dd, dt {
    padding: 0;
    margin: 0;
    list-style: none;
}

h1, h2, h3, h4 {
    margin: 0;
    font-weight: 600;
}

h1 {
    font-size: var(--font-size-l);
    margin-bottom: var(--space-l);
    margin-top: var(--space-m);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

h1[fixed-title][now~=fixed] {
    opacity: 0;
}

[is=fixed-title] {
    position: fixed;
    top: calc(var(--space-s) + var(--space-xxs) + var(--top-gap));
    left: var(--space-xl);
    z-index: 10;
    font-size: var(--size-s);
    right: var(--space-xl);
    width: calc(100% - (2* var(--space-xl)));
    text-align: center;
}


h2 {
    font-size: var(--font-size-ml);
}

h3 {
    font-size: var(--font-size-ms);
}

h4 {
    font-size: var(--font-size-m);
}

a {
    text-decoration: none;
    color: var(--forground-color);
}

p {
    margin-bottom: var(--space-m);
}

input, select {
    margin: 0;
}

[i18n] {
    visibility: hidden;
}

[is=list],
[is$=-list]{
    padding: var(--space-m) 0 var(--space-m) var(--space-m);
}

[is=list] > li,
[is$=-list] > li{
    padding-left: var(--space-s);
    padding-bottom: var(--space-s);
    list-style: decimal;
}

[is=number-list]{
    list-style: decimal;
}

li[is=section-list-item]{
    list-style: none;
}

[hidden] {
    display: none !important;
}

[has~=underline]{
    text-decoration: underline;
}

[has~=relative-position]{
    position: relative;
}

code {
    font-family: 'Fira Code', monospace;
    font-size: var(--font-size-s);
    padding: var(--space-s);
    background: var(--background-lighter-color);
    display: blocK;
}
