[is=filter]{
    --button-width: calc(var(--size-l) * 2);

    align-self: center;
    text-align: center;
    display: flex;
    align-items: stretch;
    justify-content: center;
    overflow: hidden;
}

[is=filter] > input[type=text]{
    --padding: var(--space-xs);
    --font-size: var(--size-m);
    --background: var(--background-darker-color);
    --color: var(--foreground-color);

    display: inline-block;
    padding: var(--padding);
    font-size: var(--font-size);
    width: calc(100% - var(--button-width));
    background: var(--background);
    border: none;
    color: var(--color);
    outline: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
   /* background:*/
}

[is=filter] > button{
    --background: var(--background-darker-color);
    --color: var(--forground-color);

    width: var(--button-width);
    vertical-align: center;
    overflow: hidden;
    background: var(--background);
    border: none;
    color: var(--foreground-color);
    outline: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}



filter-page {
 /*   padding-top: calc(var(--space-xl) + var(--space-s));*/
    display: block;
}

filter-page > fleebs-loader {
    padding-top: 28vh;
}

filter-page fleebs-header:has([is=filter-options]) + fleeb-list {
    top: calc(var(--size-xl) + var(--size-m) + var(--size-m) + var(--top-gap));
    height: calc(100vh - (var(--size-xl) + var(--size-s)) - var(--size-m) - var(--top-gap) - var(--bottom-gap));
}



/*
[has~=mobile-layout] filter-page {
    padding-top: var(--space-l);
}*/

[is=bar] [is=filter-options] {
    position: absolute;
    top: var(--space-xl);
    left: 0;
   /* width: clac(var(--size-xxl) * 2);*/
    width: 100%;
    display: grid;
   
    grid-template-columns: 1fr  clamp(30px, 15vw, 15vw) var(--space-xl);/*clamp(40px, 20vw, 20vw);*/
}

[has~=mobile-layout] [is=filter-options] {
    top: calc(var(--space-l) + var(--top-gap));
    z-index: 2;
    width: calc(100vw - (2 * var(--space-s)));
    grid-template-columns: 1fr;
    display: none;
}

[has~=mobile-layout] [is=filter]:has([now~=opened]) + [is=filter-options] {
    display: grid;
}

[has~=mobile-layout]  [is=filter-options] a {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}





[is=bar] [is=filter-options] ul {
    align-self: right;
    display: flex;
    justify-self: right;
    margin-right: var(--space-m);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden
}


[is=bar] [is=filter-options] a {
    display: inline-block;
    font-size: var(--font-size-s);
    padding: var(--space-xs);
    background: var(--background-darker-color);
    color: var(--foreground-soft-color);
    opacity: 0.8;
    cursor: pointer;

}

[is=bar] [is=filter-options] a[now~=active] {
    background: var(--background-darker-color);
    color: var(--foreground-color);
    opacity: 1;
}
/*
[is=bar] [is=filter-options]  label{
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
}

[is=bar] [is=filter-options] input[type=checkbox][has~=toggle-style] {
    margin-left: var(--space-s);
    display: inline-grid;
}
    */

