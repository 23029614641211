[is=fleeb-teaser]{
    --size: var(--columns-width);

    height: var(--size);
    width: var(--size);
    position: relative;
    display: grid;
    grid-template-columns: 25px 1fr;
    border-radius: 10px;
    will-change: transform;
   /* transform: translateZ(0); */

   /* box-shadow: 2px 2px 4px #000;*/
}

[has~=mobile-layout] [is=fleeb-teaser]{
    grid-template-columns: 20px 1fr;
    border-radius: 5px;
}

[is=fleeb-teaser][has~=init-animation]{
    animation-name: bubble;
    animation-duration: 1s;
    position: relative;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[is=fleeb-teaser][has~=bottom-animation]{
    animation-name: bottom-animation;
    animation-duration: 1s;
    animation-timing-function: ease-out;
}

[is=fleeb-teaser][has~=left-animation]{
    animation-name: left-animation;
    position: relative;
    animation-duration: 1s;
    
    animation-timing-function: cubic-bezier(0.69, 0.68, 0.32, 1.27); /*cubic-bezier(0.175, 0.885, 0.32, 1.275);*/
}

[is=fleeb-teaser][has~=dummy-layout] {
    display: inline-block;
    background: linear-gradient(90deg, var(--grey-darker-color),  var(--grey-dark-color), var(--grey-darker-color));
    background-size: 400% 400%;

    animation: TeaserLoadingAnimation 2s ease infinite;
    animation-fill-mode: forwards;
    width: 100%;
    min-height: 80px;
}

@keyframes TeaserLoadingAnimation {
    0%{
        background-position:0% 50%
    }
    50%{
        background-position:100% 50%
    }
    100%{
        background-position:0% 50%
    }
}


[has~=mobile-layout] [is=fleeb-teaser] [is="icon-button"]::before,
[has~=mobile-layout] [is=fleeb-teaser] [has*="-icon"]::before {
    --font-size: var(--size-sm);
}

@keyframes bubble {
    0% {
        padding: 0;
        border-radius: 50%;
        height: 0;
        width: 0;
        transform: translate3D(calc(var(--size) / 2), 0, 0);
        margin: 0;
        opacity: 0;
    }
    60% {
        border-radius: 50%;
        transform: translate3D(calc((var(--size) / 2) - (var(--size) / 8)), calc(var(--size) / 4), 0);
        height: calc(var(--size) / 4);
        width: calc(var(--size) / 4);
        margin-bottom: calc(var(--size) / 2);
        opacity: 1;
    }
    100% {
        border-radius: 5px;
        transform: translate3D(0, 0, 0);
        height: var(--size);
        width: var(--size);
        margin-bottom: var(--gap);
    }
}


@keyframes bottom-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes left-animation {
    0% {
        margin-left: calc(var(--size) * -1);
        opacity: 0;
    }
    100% {
        margin-left: 0;
        opacity: 1;
    }
}


[is=fleeb-teaser] [is="content"]{
    background: var(--grey-dark-color);
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: var(--text-font-size);
    display: grid;
    font-size: var(--font-size-s);

    grid-template-rows: 60% calc(40% - 30px) 30px;
}


[has~=mobile-layout] [is=fleeb-teaser] [is="content"]{
    grid-template-rows: 40% calc(60% - 25px) 35px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


[is=fleeb-teaser][has~=text-layout] [is=content]{
    grid-template-rows: calc(100% - 30px) 30px;
}


[is=fleeb-teaser][has~=text-layout] picture-loader{
    position: absolute;
    display: none;
}


[has~=mobile-layout] [is=fleeb-teaser][has~=text-layout] [is=content]{
    grid-template-rows: calc(100% - 25px) 25px;
}



[is=fleeb-teaser][has~=init-animation] [is="content"]{
    /*
    animation-name: content;
    animation-duration: 1s;
    animation-timing-function:  cubic-bezier(0.175, 0.885, 0.32, 1.275);
    */
    animation-name: none;
    display: none;
}


[is=fleeb-teaser][has~=init-animation] [is="content"]{
    animation-name: none;
    display: none;
}

[is=fleeb-teaser][now~=animation-ended] [is="content"]{
    display: grid;
}
/*
@keyframes content {
    0% {
        opacity: 0;
    }
    80%{
        opacity: 0;
       filter: blur(20px);
    }
    100% {
        opacity: 1;
    }
}*/

@keyframes slow-content {
    0% {
        background: var(--background-color);
    }
    80%{
        background: var(--background-color);
    }
    100% {
        background: var(--grey-dark-color);
    }
}


[is=fleeb-teaser] picture-loader{
    position: relative;
}

[is=fleeb-teaser] picture-loader picture {
    height: 100%;
    width: 100%;
    display: inline-block;
}


[is=fleeb-teaser] [is=content] picture:nth-child(2){ 
    position: absolute;
    left: 0;
}


[is=fleeb-teaser] [is="content"] picture {
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

[is=fleeb-teaser] [is="content"] picture img {
    object-fit: cover;
    max-width: 100%;
}

[is=fleeb-teaser] [is="content"] a,
[is=fleeb-teaser] [is="content"] h2,
[is=fleeb-teaser] [is="content"] p{
    padding: var(--space-s);
    padding-bottom: 0;
    margin: 0;
    width: 100%;
    overflow: hidden;
}

[is=fleeb-teaser] [is="content"] a {
    padding-top: var(--space-xxs)
}

[is=fleeb-teaser] [is="content"] h2{
    --lines: 10;
    --line-height: var(--font-size-m);
    --font-size: var(--font-size-sm);

    font-size: var(--font-size-sm);
    line-height: var(--line-height);

    position: relative;
  /*  max-height: calc((var(--line-height) * var(--lines)) + var(--space-s));*/
    overflow: hidden;
    overflow-wrap: anywhere;
    display: -webkit-box;
    -webkit-line-clamp: var(--lines);
    -webkit-box-orient: vertical;

    max-height: calc(
        ((var(--lines) + 1) * var(--font-size))
        + (
            (var(--lines)) 
            * (var(--line-height) - var(--font-size))
        )
    );
}

[has~=mobile-layout] [is=fleeb-teaser] [is="content"] h2{
  /*  max-height: calc((var(--line-height) * var(--lines)) + var(--space-xs) + 3px);*/
}



[is=fleeb-teaser][has~=text-layout] [is="content"] h2{
    align-self: center;
    justify-self: center;
}

[has~=mobile-layout] [is=fleeb-teaser] [is="content"] h2{
    --font-size: var(--font-size-s);
    --line-height: var(--font-size-sm);
    font-size: var(--font-size);
    padding: var(--space-xs);
    padding-bottom: 0;
}

[is=fleeb-teaser] [is="content"] [is=link],
[is=fleeb-teaser] [is="content"] a {
    color: var(--forground-color);
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
}

[has~=mobile-layout] [is=fleeb-teaser] [is="content"] [is=link],
[has~=mobile-layout] [is=fleeb-teaser] [is="content"] a {
    padding: var(--space-xs);
    padding-top: var(--space-xxs);
}

[is=fleeb-teaser] [is="date"]{
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    padding: var(--space-s);
}

[is=fleeb-teaser] [is=info]{
    display: grid;
    grid-template-rows: 1fr 38px;
    align-items: top;
    text-align: center;
    justify-content: center;
    justify-items: center;
    padding-top: var(--space-s);
    animation-name: none;
}

[has~=mobile-layout] [is=fleeb-teaser] [is="info"]{
    grid-template-rows: 1fr 28px;
    padding-top: 0;
}

[is=fleeb-teaser][has~=init-animation] [is=info]{
    display: none;
}

[is=fleeb-teaser][now~=animation-ended] [is="info"]{
    display: grid;
}

@keyframes info {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

[is=fleeb-teaser] [is=icon]{
    height: 16px;
    width: 16px;
    vertical-align: bottom;
    text-align: center;
    align-self: center;
}

[has~=mobile-layout] [is=fleeb-teaser] [is=icon] {
    height: 12px;
    width: 12px;
}

[is=fleeb-teaser] [is=like-button]{
    --pos: calc(var(--space-xs));

    position: absolute;
    top: var(--pos);
    right: var(--pos);
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
}

[is=fleeb-teaser]:hover [is=like-button]{
    opacity: 1;
}

[is=fleeb-teaser][has~=detail-view]{
    display: fixed;
}

[is=index-date]{
    font-size: var(--font-size-xs);
    line-height: var(--font-size-s);
    display: block;
    position: absolute;
    top: var(--space-xxs);
    left: var(--space-s);
    text-shadow: 1px 1px 3px #000, -1px -1px 3px #000;
    z-index: 2;
}

[is=affiliate-note]{
    font-size: var(--font-size-s);
    line-height: var(--font-size-sm);
    display: block;
    position: absolute;
    top: var(--space-xxs);
    right: var(--space-s);
    text-shadow: 1px 1px 3px #000, -1px -1px 3px #000;
    z-index: 2;
}


/* Teaser Properties */
[is=fleeb-teaser] [is=teaser-properties] {
    display: flex;
    padding-left: 0.75rem;
    padding-left: var(--space-s);
    padding-right: 0.75rem;
    padding-right: var(--space-s);
    gap: 0.75rem;
    gap: var(--space-s);
    margin-top: 0.25rem;
    margin-top: var(--space-xxs);
}

[is=fleeb-teaser] [is=offer] + [is=text-row] {
    max-height:calc(100%);

}

[is=fleeb-teaser] [is=content] [is=title-part]{
  max-height:calc(100% - 30px);
}

[is=fleeb-teaser] [is=content][is=title-part] h2,
[is=fleeb-teaser] [is=content][has~=offer] [is=title-part] h2 {
    padding-bottom: 0;
}

[is=fleeb-teaser] [is=content][has~=offer] h2 {
    padding-top: var(--space-m);
}


[has~=mobile-layout] [is=fleeb-teaser] [is=content][has~=offer] h2 {
    padding-top: var(--space-s);
}

[is=fleeb-teaser] [is=offer] + [is=text-row] h2{
    padding-top: var(--space-m);
  /*  padding-bottom: var(--space-s);*/
    max-height: calc(
        ((var(--lines) + 1) * var(--line-height))
        + (
            (var(--lines)) 
            * (var(--line-height) - var(--font-size))
        )
        
    );
  
}

[has~=mobile-layout] [is=fleeb-teaser] [is=content][has~=offer] [is=text-row] {
    margin-top: var(--space-xxs);
}

[has~=mobile-layout] [is=fleeb-teaser] [is=content][has~=offer] [is=text-row] h2 {
    margin-top: 0;
    text-overflow:ellipsis;
    text-wrap: wrap;
}

[has~=mobile-layout] [is=fleeb-teaser] [is=teaser-properties] [is=property-list]:nth-last-child(3) ~ [is=property-list]:last-child{
    display: none;
}

[is=fleeb-teaser] [is=property-list] {
    padding: 0;
}

[is=fleeb-teaser] [is=property-list] [is=property-description]{
    font-size: var(--font-size-xs);
}

[is=fleeb-teaser] [is=property-list] [is=property-value]{
    font-weight: bold;
}


[is=fleeb-teaser] [is=custom-content]{
    padding: var(--space-s);
    overflow: hidden;
} 

[has~=mobile-layout] [is=fleeb-teaser] [is=custom-content]{
    padding: var(--space-xs);
}



